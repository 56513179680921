import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full h-full absolute inset-0 justify-center items-end pb-32 text-center" }
const _hoisted_2 = { class: "hero-slider-carousel-item__content" }
const _hoisted_3 = { class: "text-fs-28-lh-36-ls-0 lg:text-fs-44-lh-56-ls-0 xl:text-fs-32-lh-36-ls-0 font-bold mb-s lg:mb-xxs" }
const _hoisted_4 = { class: "text-fs-16-lh-24-ls-0.4 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hero-slider-carousel-item", _ctx.textColorClass])
  }, [
    _createVNode(_component_ResponsiveImage, { responsiveImageSet: _ctx.image }, null, 8, ["responsiveImageSet"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1),
        (_ctx.url)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "w-1/2 sm:w-full md:w-auto mt-10",
              theme: _ctx.buttonTheme,
              href: _ctx.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonName), 1)
              ]),
              _: 1
            }, 8, ["theme", "href"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}